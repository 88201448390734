.popUpSettori{
    position: absolute;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 16px 0px;
}

.tileSettori{
    box-sizing: border-box;
    width: 270px;
    height: 50px;
    text-align: left;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;
    padding-left: 30px;
    cursor: pointer;
    border-bottom: solid 0.01px var(--grigio);
    font-size: 14px;
    text-transform: none;
    font-weight: 400;

    /* per fare la roba che si riempie */
    background: linear-gradient(to left, var(--blue) 50%, var(--azzurro2) 50%);
    background-size: 200% 100%;
    background-position:right bottom;
    transition:all 800ms ease-out;
}

.lastTileSettori{
    border-bottom: none;
    height: 65px;
}

.tileSettori:hover{
    background-position:left bottom;
}