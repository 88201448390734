.composizione{
    position: relative;
    min-height: 100vh;
    background-color: rgba(0, 69, 20, 0.26);
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    gap: 20px;
    color: var(--blue);
    text-align: center;
}

.backImageComposizione{
    background-image: url(./../../risorse/Immagini/MyA_aperto.png);
    background-position: center;
    background-size: cover;
    width: 35vw;
}

.cerchioComposizione{
    fill: var(--azzurro1);
    transition: all 300ms;
}

.cerchioComposizione:hover{
    fill: rgba(106, 169, 228, 0.7);
    cursor: pointer;
    transition: all 300ms;
}

.tileComposizione{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    background-color: var(--white);
    border: solid 2px rgba(106, 169, 228, 0.7);
    max-width: 40%;
    box-sizing: border-box;
    padding: 50px;
    position: relative;
    gap: 10px;
}

.imgTileComposizione{
    width: 20vw;
}

.flexCerchiComposizione{    
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
}

.tileUnicaComp{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 80%;
    box-sizing: border-box;
    padding: 50px 0;
}

.imgTileUnicaComp{
    width: 30%;
}

.colTileUC{
    display: flex;
    flex-flow: column wrap;
    text-align: left;
    max-width: 50%;
}

.terzaTile{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    gap: 50px;
    width: 80%;
    padding: 50px 0 200px;
}

.btnRigaChiller{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 50px;
}

.txtChiller{
    text-align: center;
    max-width: 925px;
}

.btnChiller{
    min-width: 275px;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 10px 20px;
    text-align: center;
    box-sizing: border-box;
}
@media only screen and (max-width: 1216px) {
    .btnRigaChiller{
        gap: 30px;
    }
    @media only screen and (max-width: 1166px) {
        .btnRigaChiller{
            gap: 20px;
        }
        @media only screen and (max-width: 1023px) {
            .backImageComposizione{
                width: 60%;
            }

            .imgTileComposizione{
                width: 100%;
            }

            .tileUnicaComp{
                width: 100%;
            }

            .terzaTile{
                width: 100%;
            }

            .imgTileUnicaComp{
                width: 40%;
            }

            @media only screen and (max-width: 750px) {
                .tileComposizione{
                    max-width: 60%;
                }

                .composizione{
                    justify-content: center;
                }
                
                .backImageComposizione{
                    width: 70%;
                }
                @media only screen and (max-width: 670px) {
                    .tileUnicaComp{
                        display: flex;
                        flex-flow: column wrap;
                        justify-content: center;
                        align-items: center;
                    }

                    .imgTileUnicaComp{
                        width: 60%;
                    }
                    @media only screen and (max-width: 620px) {
                        .btnChiller{
                            font-size: 16px;
                            min-width: 250px;
                        }
                        @media only screen and (max-width: 580px) {
                            .tileComposizione{
                                max-width: 70%;
                            }

                            .backImageComposizione{
                                width: 80%;
                            }

                            .terzaTile{
                                gap: 30px;
                                padding: 20px 0;
                            }
                            

                            @media only screen and (max-width: 450px) {
                                .tileComposizione{
                                    max-width: 80%;
                                }
                    
                                .backImageComposizione{
                                    width: 90%;
                                }

                                .imgTileUnicaComp{
                                    width: 80%;
                                }

                                @media only screen and (max-width: 370px) {
                                    .tileComposizione{
                                        max-width: 90%;
                                    }
                        
                                    .backImageComposizione{
                                        width: 100%;
                                    }

                                    .imgTileUnicaComp{
                                        width: 90%;
                                    }

                                    .btnChiller{
                                        font-size: 16px;
                                        min-width: unset;
                                        width: 230px;
                                    }
                                    
                                    @media only screen and (max-width: 340px) {
                                        .tileComposizione{
                                            max-width: 100%;
                                        }

                                        .imgTileUnicaComp{
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }                        
                    }
                }
            }
        }
    }
}