.popUpProdotti{
    box-sizing: border-box; 
    left: 0;
    position: absolute;
    background-color: var(--white);
    border-top: solid 1px var(--grigio);
    width: 100vw;
    padding: 30px 180px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 16px 0px;
}

.tileProdotti{
    max-width: 32%;
    box-sizing: border-box; 
    padding: 20px 10px;
    gap: 20px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
}

.titolettiProdotti{
    box-sizing: border-box; 
    padding-left: 5px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.serieProdotti{
    font-size: 16px;
    font-weight: 700;
    color: var(--blue);
    cursor: pointer;
    letter-spacing: 0.5%;
}

.gammaProdotti{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.txtGamma{
    font-size: 16px;
    color: var(--grigio);
    font-weight: 700;
    cursor: pointer;
    transition: color 0.3s;
    letter-spacing: 0.5%;
}

.txtGamma:hover{
    color: var(--blue);
}

.invisibleProdotti{
    visibility: hidden;
}

.vediTuttiProdotti{
    color: var(--azzurro1);
    letter-spacing: 0.5%;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.barraProdotti{
    height: 1px;
    width: 40px;
    margin-left: -5px;
    background-color: var(--grigio);
}