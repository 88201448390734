.MyA{    
    position: relative;
    min-height: 100vh;
    background-image: url(./../../risorse/Immagini/sfondo_onde_verdi.png);
    background-position: center;
    background-size: cover;
    padding: 0 160px;
    box-sizing: border-box;
    color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexMyA{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;    
}

.textMyA{
    width: 50%;
}

.imgMyA{
    width: 40%;
}

@media only screen and (max-width: 1100px) {
    .MyA{
        padding: unset;
    }
    .flexMyA{
        width: 90%;
    }
    @media only screen and (max-width: 850px) {
        .MyA{
            padding: 30px;
        }

        .textMyA{
            width: unset;
        }

        .flexMyA{
            text-align: center; 
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            gap: 30px;
        }

        .imgMyA{
            width: 60%;
        }

        @media only screen and (max-width: 650px) {
            .imgMyA{
                width: 75%;
            }

            @media only screen and (max-width: 500px) {
                .imgMyA{
                    width: 80%;
                }

                @media only screen and (max-width: 400px) {
                    .imgMyA{
                        width: 100%;
                    }
                }
            }
        }
    }
}