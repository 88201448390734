.vantaggi{
    position: relative;
}

.frameVantaggi{
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
    padding: 60px 140px 140px 140px;
}

.boxVantaggi{    
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: flex-start;
    gap: 30px;
    width: fit-content;
    box-sizing: border-box;
    padding: 20px;
}

.normalBGBoxVantaggi{
    background-color: rgba(0, 69, 20, 0.2);
}

.diffBGBoxVantaggi{
    background-color: rgba(0, 69, 20, 0.5);
}

.flexCerchiVantaggi{
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    gap: 20px;
}

.numeriCerchiVantaggi{
    border: 2px solid;
    font-weight: 300;
    font-size: 20px;
    border-radius: 1000px;
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
}

.titoloDescVantaggi{
    width: 630px;
    color: var(--white);
}

@media only screen and (max-width: 1030px) {
    .frameVantaggi{
        display: flex;
        justify-content: center;
    }

    .boxVantaggi{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: unset;
    }

    .normalBGBoxVantaggi{
        background-color: unset;
    }
    
    .diffBGBoxVantaggi{
        background-color: unset;
    }
    
    .diffBGBothVantaggi{
        padding: 20px;
        box-sizing: border-box;
        background-color: rgba(0, 69, 20, 0.5);
    }

    .normalBGBothVantaggi{
        padding: 20px;
        box-sizing: border-box;
        background-color: rgba(0, 69, 20, 0.2);       
    }
    
    .flexCerchiVantaggi{
        flex-direction: row;
    }

    .titoloDescVantaggi{
        text-align: center;
    }
    @media only screen and (max-width: 935px) {
        .frameVantaggi{
            padding: 60px 10px 140px;
        }
        @media only screen and (max-width: 675px) {
            .titoloDescVantaggi{
                width: 90%;
            }
            @media only screen and (max-width: 376px) {
                .flexCerchiVantaggi{
                    width: 90%;
                    justify-content: space-between;
                    gap: unset;
                }
                .numeriCerchiVantaggi{
                    width: 55px;
                    height: 55px;
                    line-height: 55px;
                }
                @media only screen and (max-width: 345px) {
                    .numeriCerchiVantaggi{
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                    }      
                    @media only screen and (max-width: 310px) {
                        .numeriCerchiVantaggi{
                            width: 45px;
                            height: 45px;
                            line-height: 45px;
                        }                    
                    }              
                }
            }
        }
    }
}

@media only screen and (max-height: 650px) {
    .frameVantaggi{
        padding: 20px 10px;
    }     
}  