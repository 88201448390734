.contattaci{
    cursor: pointer;
    color: white;
    position: fixed;
    right: 0;
    top: calc(50% - 40px);
    margin-right: -50px;
    z-index: 20;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;
    background-color: rgba(0, 36, 69, 0.8);
    padding: 7px 10px;
    transform: rotate(-90deg);
}

@media only screen and (max-width: 1200px) {
    .svgContattaci{
        height: 20px;
    }
}