.contenitoreComponentiNavbar{
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
}

.navbar{
    height: 100px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 80px;
    background-color: var(--white);
    transition: all 300ms ease-out;
}

.navbarBlu{
    background-color: var(--blue);
    transition: all 300ms ease-out;
}

.linkLogoNav{
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

.logoNavbar{
    height: 60px;
    cursor: pointer;
}

.destraNav{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end; 
    align-items: center;
    gap: 40px;
    height: 100%;
}

.linguaNav{
    font-size: 16px;
    font-weight: 700;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.burgerMenu{
    width: 100px;
    background-color: var(--blue);
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    color: var(--white);
}

.iconaLinguaNav{
    width: 25px;
    height: 25px;
}

.titoliNav{
    margin-left: -85px;
    color: var(--grigio);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 55px;
    height: 100%;
}

.titoloNav{
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: solid 8px transparent;
    border-top: solid 8px transparent;
    transition: color 0.3s;
}

.titoloPopNav{
    box-sizing: border-box;
    height: 100px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    transition: border-bottom 0.3s;
}

.titoloNav:hover{ 
    color: var(--blue);
}

.tilteHovering{
    border-bottom: solid 8px var(--blue);
    color: var(--blue);
}
@media only screen and (max-width: 1600px) {
    .navbar{
        height: 70px;
    }

    .burgerMenu{
        width: 70px;
    }

    .titoloPopNav{
        height: 70px;
    }

    .logoNavbar{
        height: 40px;
    }
    
    .titoliNav{
        gap: 30px;
        font-size: 14px;
    }

    .navbar{
        padding-left: 20px;
    }
    

    @media only screen and (max-width: 1200px) {
        .titoliNav, .hideProdotti{
            display: none;
        }

        .destraNav{
            gap: 20px;
        }
    }
}