*{
  margin: 0;
  padding: 0;
  font-family: "Titillium Web",Arial,sans-serif;
}

:root {
  --blue: #002445;
  --white: #ffffff;
  --azzurro1: #6AAAE4;
  --azzurro2: #558FC4;
  --azzurro3: #0078AA;
  --grigio: #808080;
  --verde: #004514;
}

a{
  all: unset;
}

.bodyStopOverflow{
  overflow-y: hidden;
}

.scrollArrow{
  position: absolute;
  left: 50px;
  bottom: 50px;
  cursor: pointer;
}

/* transizione pop up */
/*transition: transition-property transition-duration transition-timing-function transition-delay; */

.showPopUp{
  animation: fadein 800ms;
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

/* transizione pop up lingue */


img{
  width: 100%;
}

.hideThings{
  display: none;
}

.marginFromNavbar{
  margin-top: 100px;
}

.titoloSezione{
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 1px;
}

.titoletto{
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 1px;
}

.subscript50{
  position: absolute;
  padding-top: 31px;
  font-size: 31px;
}

.subscript24{
  position: absolute;
  padding-top: 15px;
  font-size: 15px;
}

.subscript18{
  position: absolute;
  padding-top: 11px;
  font-size: 11px;
}

.testo24{
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 0.5px;  
}

.testo18{
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.5px;  
}

@media only screen and (max-width: 1600px) {
  .marginFromNavbar{
    margin-top: 70px;
  }

  .titoloSezione{
    font-size: 40px;
  }

  .titoletto{
    font-size: 26px;
  }

  .subscript50{
    padding-top: 25px;
    font-size: 25px;
  }

  .subscript24{
    padding-top: 13.5px;
    font-size: 13.5px;
  }

  .subscript18{
    padding-top: 9.8px;
    font-size: 9.8px;
  }

  .testo24{
    font-size: 22px;
  }
  
  .testo18{
    font-size: 16px;
  }
  @media only screen and (max-width: 1200px) {
    .titoloSezione{
      font-size: 30px;
    }

    .titoletto{
      font-size: 24px;
    }

    .subscript50{
      padding-top: 18.5px;
      font-size: 18.5px;
    }

    .subscript24{
      padding-top: 12.4px;
      font-size: 12.4px;
    }

    .subscript18{
      padding-top: 8.68px;
      font-size: 8.68px;
    }

    .testo24{
      font-size: 20px;
    }
    
    .testo18{
      font-size: 14px;
    }
    @media only screen and (max-width: 919px) {
      .hideScrollArrow{
        display: none;
      }
    }
  }
}