.menuBurgerAperto{
    height: calc(100vh - 100px);
    overflow-y: auto;
    width: 100%;
    background-color: var(--blue);
    position: fixed;
    z-index: 900;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    color: var(--white);
    box-sizing: border-box;
}

.righeBurger{
    min-width: 80%;
    min-height: 530px;
    display: flex;
    flex-flow: row wrap;
}

.colonnaBurger{
    width: 50%;
    display: flex;
    flex-flow: column wrap;
    box-sizing: border-box;
    padding: 10px 30px;
}

.colonna1Burger{  
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 96px;
    gap: 5px;
}

.flexTitoloFreccia{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 13px;
}

.paddingLinkBurger{
    padding-left: 25px;
}

.linkDropAperto{
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
}

.linkDropChiuso{
    z-index: -1;
    height: 0px;
}

.linkTxtDropAperto{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 19.2px;
    padding-left: 25px;
    transition: color 0.3s;
    cursor: pointer;
}

.linkTxtDropAperto:hover{
    color: var(--azzurro1);
}

.displayNone{
    display: none;
}

.colonna2Burger{  
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 38.4px;
    gap: 10px;    
}

.linkBurger{
    cursor: pointer;
    transition: color 0.3s;
}

.linkBurger:hover{
    color: var(--azzurro1);
}

.rowIconeSocialBurger{
    margin-top: 25px;
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}

.iconeSocialBurger{
    cursor: pointer;    
    transition: all 300ms linear 0s; 
}

@media only screen and (max-width: 1600px) {
    .menuBurgerAperto{
        height: calc(100vh - 70px);
    }

    .colonna1Burger{  
        font-size: 50px;
        line-height: 80px;
    }

    .colonna2Burger{  
        font-size: 22px;
        line-height: 35.2px;
    }
    @media only screen and (max-width: 1200px) {
        .colonna1Burger{  
            font-size: 40px;
            line-height: 64px;
        }
    
        .colonna2Burger{  
            font-size: 18px;
            line-height: 28.8px;
        }
        @media only screen and (max-width: 680px) {
            .righeBurger{
                flex-flow: column;
            }
            
            .colonna1Burger{  
                font-size: 30px;
                line-height: 48px;
            }

            .menuBurgerAperto {
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 20px 0;
            }

            .righeBurger {
                min-width: 100%;
                gap: 20px;
            }
            .colonnaBurger {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-height: 600px) {
    .menuBurgerAperto{
        justify-content: flex-start;
    }
}