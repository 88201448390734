.funzioni{    
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
}

.column1Funzioni{
    min-width: 40%;
    min-height: 100vh;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 80px 30px;
    background-color: rgba(0, 69, 20, 0.26);
}

.flexrowFunzioni{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
}

.column2Funzioni{
    min-width: 60%;
    min-height: 100vh;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 160px 160px 160px 80px;
    color: var(--blue);
}

.tileFunzioni{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.imgFunzione{
    width: 40%;
}

.flexcolTileFunz{
    display: flex;
    flex-flow: column wrap;
    gap: 10px;
    max-width: 55%;
}

.flexTitleFunz{
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}

.iconaTileFunz{
    fill: var(--blue);
    width: 35px;
}

@media only screen and (max-width: 1400px) {
    .column2Funzioni{
        padding: 0 40px;
    }
    @media only screen and (max-width: 1023px) {
        .funzioni{    
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-evenly;
            background-color: rgba(0, 69, 20, 0.26);
        }

        .row1Funzioni{
            display: flex;
            flex-flow: row wrap;
            justify-content: space-evenly;
            width: 100%;
        }

        .row2Funzioni{
            color: var(--blue);
        }

        .tileFunzioni{
            justify-content: space-evenly;
            padding: 0 30px;
            box-sizing: border-box;
        }

        .imgFunzione{
            width: unset;
            height: 50vh;
        }
        @media only screen and (max-width: 670px) {

            .funzioni{
                justify-content: center;
                gap: 30px;
                padding: 30px 0;
            }

            .tileFunzioni{
                flex-flow: column;
                align-content: normal;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }

            .flexTitleFunz{
                justify-content: center;
                text-align: center;
            }

            .txtTileFunz{
                text-align: center;
            }

            .imgFunzione{
                height: unset;
                width: 20vh;
            }
        }
    }
}

@media only screen and (max-height: 800px) {
    .column1Funzioni{
        padding: 30px 30px 80px;
    }
}