.bodyScuro{
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.popUpLingua{
    background-color: var(--white);
    padding: 50px;
    border-radius: 30px;
    color: var(--blue);
    font-weight: 600;
    font-size: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    gap: 100px;
    box-shadow: 0 0 0 9000px rgba(0, 0, 0, 0.25);
    margin: 50px;
}

.riga_bandiere{
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    overflow-y: auto;
    align-content: flex-start;
}

.tileLingua{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    gap: 5px;
    height: 150px;
    width: 180px;
    border-radius: 30px;
    box-sizing: border-box;
    border: 2px solid var(--white);
    cursor: pointer;
}

.selezione{
    border: 2px solid var(--blue);
}

.bottoni{
    align-self: flex-end;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 20px;
}

@media only screen and (max-width: 980px) {
    .riga_bandiere{
        justify-content: center;
        height: 350px;
    }

    .bottoni{
        justify-content: center;
        align-self: center;
    }     

    @media only screen and (max-width: 620px) {

        .bottoni{
            flex-flow: column;
        } 
        
        .riga_bandiere{
            display: flex;
            flex-flow: column;
            justify-content: flex-start
        }
        
        .tileLingua{
            flex-flow: row;
            height: 70px;
            width: 100%;
            gap: 20px;
            padding-left: 20px;
            justify-content: flex-start;
        }

        .bandiera{
            height: 50px;
            width: 50px;
        }
        
        .popUpLingua{
            gap: 50px;
            padding: 30px;
            margin: 10px;
        }     
    }   
}

@media only screen and (max-height: 661px) {
    .riga_bandiere{
        height: 230px;
    }
    @media only screen and (max-height: 520px) {
        .bodyScuro{
            align-items: flex-start;
        }
    }
}