.gamma{
    color: var(--blue);
    padding-top: 40px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    gap: 50px;
}

.titoloGamma{
    text-align: center;
}

.titolettoGamma{
    text-align: center;
}

.flexTileGamma{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    box-sizing: border-box;
}

.tileGamma{
    max-width: 20.01%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-bottom: 50px;
    transition: all 300ms;
}

.tileGammaHover{
    transition: all 300ms;
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px 0px;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative;
}

.scopriGamma{
    position: absolute;
    background-color: var(--blue);
    top: 40%;
    padding: 15px 30px;
    color: var(--white);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 2px;
}

.tile_descGamma{
    display: flex;
    flex-flow: row wrap;
    gap: 30px;
    padding-left: 160px;
    box-sizing: border-box;
}

.rigaGamma{
    background-color: var(--blue);
    width: 2px;
    height: 90%;
}

.descriptionGamma{
    color: var(--blue);
}

@media only screen and (max-width: 1023px) {

    .gamma{
        padding: 30px;
    }
    
    .tileGamma{
        max-width: 25%;
    }

    .scopriGamma{
        padding: 15px 20px;
    }

    @media only screen and (max-width: 720px) {

        .descriptionGamma{
            margin-top: 20px;
        }

        .scopriGamma{
            padding: 15px 30px;
        }

        .tileGamma{
            max-width: 60%;
        }

        @media only screen and (max-width: 550px) {

            .tileGamma{
                max-width: 80%;
            }

            @media only screen and (max-width: 420px) {

                .tileGamma{
                    max-width: 90%;
                }

                @media only screen and (max-width: 350px) {

                    .tileGamma{
                        max-width: 100%;
                    }
                }
            }
        }
    }
}