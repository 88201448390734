.sostenibilita{
    height: calc(100vh - 100px );
    min-height: 100%;
    background-image: url(./../../risorse/Immagini/Sostenibilità.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    padding-bottom: 160px;
    padding-left: 160px;
    box-sizing: border-box;
    position: relative;
}

.bannerSostenibilita{
    box-sizing: border-box;
    padding: 50px;
    background-color: var(--verde);
    color: var(--white);
    text-align: left;
    width: max-content;
    display: flex;
    flex-flow: column wrap;
    gap: 5px;
}

.boxTestoSostenibilita{
    max-width: 600px;
}

.righettaSostenibilità{
    background-color: var(--white);
    height: 1px;
    width: 10%;
}

.txtScrollArrow{
    display: none;
}


@media only screen and (max-width: 1600px) {
    .sostenibilita{
        height: calc(100vh - 70px );
    }

    .boxTestoSostenibilita{
        max-width: 550px;
    }
    @media only screen and (max-width: 1200px) {
        .boxTestoSostenibilita{
            max-width: 500px;
        }
        @media only screen and (max-width: 919px) {
            .righettaSostenibilità{
                width: 50px;
            }

            .sostenibilita{
                padding: 0;
                padding: 50px 0 10px 0;
                display: flex;
                align-content: center;
                justify-content: space-between;
                align-items: center
            }

            .bannerSostenibilita{
                text-align: center;
                align-items: center;
            }

            .scrollArrowContainerSost{
                display: flex;
                flex-flow: column wrap;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }

            .arrowSostenibilità{
                all: unset;
                height: 25px;
            }

            .txtScrollArrow{
                all: unset;
                color: var(--white);
                opacity: 0.7;
                font-size: 20px;
            }
            @media only screen and (max-width: 680px) {
                .bannerSostenibilita{
                    padding: 30px;
                    width: 90%;
                }
                .sostenibilita{
                    padding-top: 20px;
                    background-image: url(./../../risorse/Immagini/Sostenibilità2.jpg);
                }
            }
        }
    }
}
  
@media only screen and (max-height: 440px) {
    .sostenibilita {
        padding: 10px 0 10px 0;
    }
    @media only screen and (max-height: 400px) {
        .sostenibilita {
            justify-content: flex-start;
            height: fit-content;
        }    
    }
}