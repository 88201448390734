.footer{
    background-color: var(--blue);
    color: var(--white);
    padding: 50px 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.rowIconeSocialFooter{
    margin-top: 25px;
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}

.iconeSocialFooter{
    width: 30px;
    cursor: pointer;    
}

.logoFooter{    
    width: 150px;
    cursor: pointer;
}

.colFooter{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    gap: 20px;
}

.textFooter{
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: rgb(166, 172, 182);
}

.withMaxFooter{
    max-width: 640px;
}

.txtBoldFooter{
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.5px;
}

.buttonFooter{
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.5px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 1000px;
    padding: 10px 15px;
    transition: all 300ms;
}

.buttonFooter:hover{    
    border-color:rgb(255, 255, 255);
    transition: all 300ms;
    cursor: pointer;
}

.btnDxFooter{
    width: 245px;
    min-height: 47px;
}

.col2Footer{
    align-items: flex-end;
}

.iconeFooter{
    width: 25px;
    fill: var(--white);
}

@media only screen and (max-width: 1023px) {
    .footer{
        flex-direction: column;
        justify-content: normal;
        align-items: center;
        text-align: center;
        gap: 30px;
    }

    .colFooter{
        align-items: center;
    }

    .withMaxFooter{
        max-width: unset;
    }
}