.performance{
    position: relative;
    min-height: 100vh;
    padding: 80px 160px;
    box-sizing: border-box;
    color: var(--blue);
}

.titoloPerformance{
    text-align: center;
    width: 100%;
}

.flexPerformance{    
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 100px;
}

.flexTilesPerformance{    
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.tilePerformance{
    width: 30%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.svgTilePerformance{
    width: 55%;
    margin-bottom: 30px;
}

@media only screen and (max-width: 1536px) {
    .performance{
        padding: 40px 160px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    @media only screen and (max-width: 1020px) {
        .performance{
            padding: 30px 0 ;
        }

        .flexPerformance{ 
            width: 90%;
        }

        .flexTilesPerformance{    
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: flex-start;
            gap: 50px;
        }

        .tilePerformance {
            width: 100%;
        }

        .svgTilePerformance{
            width: 25%;
        }

        @media only screen and (max-width: 820px) {
            .svgTilePerformance{
                width: 30%;
            }

            .flexPerformance{ 
                width: 100%;
            }

            .performance{
                padding: 30px;
            }

            @media only screen and (max-width: 700px) {
                .svgTilePerformance{
                    width: 40%;
                }  

                .flexPerformance{    
                    gap: 70px;
                }

                @media only screen and (max-width: 400px) {
                    .svgTilePerformance{
                        width: 50%;
                    }     

                    .flexPerformance{    
                        gap: 50px;
                    }          
                }       
            }
        }
    }
}

@media only screen and (min-height: 1081px) {
    .performance{
        padding: 0 160px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}