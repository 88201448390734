.gwp{
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 80px 160px 160px 160px;
    color: var(--verde);
    gap: 50px;
}

.spiegazioneGwp{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
}

.graficoGwp{
    width: 33%;
    cursor: pointer;
    transition: all 800ms;
}

.graficoCliccatoGwp{
    width: 45%;
}

.titleDescGwp{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
}

.xGwp{
    position: absolute;
    height: 25px;
    width: 25px;
    top: 0;
    right: 0;
    margin-top: 5%;
    margin-right: 5%;
    cursor: pointer;
    transition: all 800ms;
}

.xGwp:hover{
    opacity: 0.6;
}

.flexGraficiGwp{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
}

/* .immagineGraficoGwp{
    border: solid 1px rgba(0, 69, 20, 0.8);
    background-color: rgba(0, 69, 20, 0.8);
} */

@media only screen and (max-width: 1500px) {
    .flexGraficiGwp {
        flex-flow: column ;
        gap: 50px;
    }

    .graficoGwp{
        width: 70%;
        cursor: unset;
    }

    .hideScrollArrowGwp{
        display: none;
    }

    .gwp{
        justify-content: flex-start;
        gap: 100px;
    }
    @media only screen and (max-width: 919px) {
        .gwp{
            padding: 0;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .spiegazioneGwp{
            max-width: 600px;
            padding: 0 30px;
        }

        .flexGraficiGwp{
            max-width: 600px;
            padding: 0 30px;
        }

        .graficoGwp{
            width: 100%;
        }
    }
}