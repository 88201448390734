.blu{
    background-color: var(--blue);
    color: var(--white);
    border: 1px solid var(--blue);
    transition: all 300ms;
}

.blu .iconaBottone{
    fill: var(--white);
}

.bianco{
    background-color: var(--white);
    color: var(--blue);
    border: 1px solid rgba(0, 36, 69, 0.5);
    transition: all 300ms;
}

.bianco .iconaBottone{
    fill: var(--blue);
}

.bianco:hover{
    border: 1px solid rgba(0, 36, 69);
    transition: all 300ms;
}

.blu:hover{
    background-color: rgb(0, 52, 99);
    transition: all 300ms;
}

.bottone{
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 10px 20px;
    min-width: 215px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.iconaBottone{
    width: 25px;
}
@media only screen and (max-width: 620px) {
    .bottone{
        font-size: 16px;
    }
}
